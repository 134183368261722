import styled from '@emotion/styled'
import { Icon } from 'antd'

export const Root = styled.div`
  margin-bottom: 25px;
  font-size: 36px;
  height: 100vh;
  width: 100%;
  text-align: center;
`

export const Wrap = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -150px;
  width: 100%;
`

export const StyledIcon = styled(Icon)`
  font-size: 52px;
`

export const P = styled.p`
  margin-top: 50px;
  font-size: 28px;
  font-family: Montserrat;
  text-transform: uppercase;
  color: #ff6b00;
`
