import { Checkbox } from "antd";
import styled from "@emotion/styled";

export default styled(Checkbox.Group)`
  .ant-row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
  }
`;
