import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'

const apiServices = async (method, path, data) => {
  try {
    const uri = process.env.REACT_APP_API_URL
    const request = {
      method,
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: JSON.stringify(data),
    }
    return await fetch(`${uri}${path}`, request)
  } catch (e) {
    console.error(e)
  }
}

export { apiServices }
