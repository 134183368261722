import { Link } from '@reach/router'
import styled from '@emotion/styled'

export const Root = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Img = styled.img`
  height: 42px;
  vertical-align: middle;
`

export const StyledLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
`

export const Version = styled.div`
  color: #999;
  display: inline-block;
  font-size: 9px;
  padding: 5px 10px;
  text-align: right;
  vertical-align: top;
`
